import React from 'react';
import './css/Main.css';
import './css/Ai.css';
import './css/Metahuman.css';
import './css/Gaming.css';
import './css/Contact.css';
import './css/Footer.css';
import logo from './assets/img/glasir_logo_col_tree.png';


function Main() {


    return (
      <div className="main">
        <header className="main-header">
            <h1 className='main-header-text'>Glasir</h1>
            <div className='main-text'>Technology Services</div>

            <div className="logo-box">
                <img className="logo-fit" alt='logo' src={logo} /> 
            </div>
            
            
            <div className='main-text'>AI, Metahumans, Game Development, Web, Mobile</div>
        </header>
        
        <div className="main-content">
            
            <div className="ai-content">
                <div className="ai-header">
                    <div>Artificial Intelligence</div>
                </div>
                <div className="ai-text">
                    <p>Glasir is an AI first company. We work with most Natural Language Processing (NLP) services. Get started today and plug your chatbot into a Metahuman ,web or mobile project. Build your solution with OpenAI(GP3), Dialogflow, wit.ai or any other NLP service.</p>
                    <p>AI is not only for language processing. Glasir also uses it for coding! All our developers utilise the latest technology in AI code completion, giving them up to a 50% productivity boost! We can assist with many web and mobile projects.</p>
                </div>
            </div>

            <div className="metahuman-content">
                <div className="metahuman-header">
                    <div>MetaHumans</div>
                </div>
                <div className="metahuman-text">
                    <p>Glasir Games has been experimenting with, AI, Unreal Engine and Metahumans. When all these technologies are combined a life like Avatar is produced that can hold a conversation with you! Check out our video showcasing Sarah.</p>
                    <div className="video-responsive">
                        <iframe
                        width="853"
                        height="480"
                        src={`https://www.youtube.com/embed/W_CKvZjXsxU`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                        />
                    </div>                   
                </div>
            </div>

            <div className="gaming-content">
                <div className="gaming-header">
                    <div>Gaming</div>
                </div>
                <div className="gaming-sub-header">
                    <div>2d web games</div>
                </div>
                <div className="gaming-text">
                    <p>Need a high quality 2d game for your website? Glasir Games can help you. With our customisable web-based gaming engine we can have you setup in no time. Full asset management, game development and easy builds are handled with a breeze. Try out our addictive word game THWORD. Available for customisation and licencing.</p>
                    <p><a href='https://glasirgames.com/thword/index.html' target='_blank' rel="noreferrer">PLAY THWORD NOW</a></p>
                </div>
                <div className="gaming-sub-header">
                    <div>3d development</div>
                </div>
                <div className="gaming-text">
                    <p>Glasir is working with the latest technology in game development. We utilise all the latest tools in game development to produce the highest quality 3d animations for Metahumans.</p>
                    <p>Here are some of the tools we use.<br/>
                        Unreal Engine<br/>
                        iClone<br/>
                        Replica Studios<br/>
                        and many more...<br/>
                    </p>
                    <p>Want to add AI to your game avatars? Or maybe build an interactive Metahuman for your business? Contact us and we can help with your Unreal Engine project.</p>                   
                    <p>Take your first steps into the Metaverse now.</p>
                </div>
               
            </div>

            <div className="contact-content">
                <div className="contact-header">
                    <div>Contact Us</div>
                </div>
                <div className="contact-text">
                    <p>Email us - info[at]glasir.ai</p>
                    <p>Book a free consultation - <a href='https://calendly.com/jari-kemppinen/consultation' target='_blank'  rel='noreferrer'>BOOK NOW</a></p>
                </div>
            </div>

            <div className="footer-content">
                <div className="footer-text">
                    <p>Copyright © Glasir Games Pty Ltd 2022</p>
                </div>            
            </div>
        </div>

      </div>
    );

}
  
export default Main;